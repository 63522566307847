








import Vue from 'vue'
import { BaseJobDetailsHeader, BaseJobDetailsData } from '@/components'

export default Vue.extend({
    name: 'Preview',

    components: { BaseJobDetailsHeader, BaseJobDetailsData },

    props: {
        job: {
            type: Object,
            required: true,
        },
    },
})
