<template lang="pug">
  tr.skill-row(:data-test="'skill-' + skill.name")
    td.cell-name.pt-1.pb-1
      label.mb-0
        | {{skill.name}}

    td.cell-seniority.pt-1.pb-1
      b-container
        b-row.text-center(align-v="center")
          .col-9.pl-0.pr-0
            Slider(
              v-model="seniority",
              name='referralTrait'
              :min='0'
              :max='100'
              :interval='5'
            )
          .col-3.p-0
            b-badge {{seniority}}

    td.cell-experience.pt-1.pb-1
      multiselect(
        :value="skill.yearsOfExperience"
        :options="[1,2,3,4,5,6,7,8,9,10,15,20]"
        :allow-empty="false"
        :searchable="false"
        select-label=""
        selected-label=""
        deselect-label=""
        @input="changeYearsOfExperience($event)"
      )

    td.cell-actions.pt-1.pb-1
      b-button.align-self-center(variant="brand" @click='remove()' size="sm" data-test="btn-delete")
        font-awesome-icon(:icon="iconTrash" v-b-tooltip="'Remove skill'")
</template>
<script>
import Multiselect from 'vue-multiselect'
import Slider from 'vue-slider-component'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'

export default {
    props: {
        skill: Object,
    },

    components: {
        Multiselect,
        Slider,
    },

    computed: {
        iconTrash: () => faTrashAlt,
        seniority: {
            get() {
                return this.skill.seniority
            },
            set(seniority) {
                this.$emit('change', {
                    ...this.skill,
                    seniority,
                })
            },
        },
    },

    methods: {
        changeYearsOfExperience(yearsOfExperience) {
            this.$emit('change', {
                ...this.skill,
                yearsOfExperience,
            })
        },
        remove() {
            this.$emit('remove')
        },
    },
}
</script>
<style lang="sass" scoped>
.skill-row .cell-name
  vertical-align: middle
  width: 30%

.skill-row .cell-name label
  color: #42bff4

.skill-row .cell-seniority
  font-size: 15px
  vertical-align: middle
  width: 50%

.skill-row .cell-experience
  vertical-align: middle
  width: 15%

.skill-row .cell-actions
  vertical-align: middle
  width: 5%
  text-align: center
</style>
