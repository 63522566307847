<template>
    <Keywords :skills="skills" @change="skillsChanged">
        <template #header>
            <p class="groupName">{{ group.groupName }}</p>
        </template>
        <template #edit>
            <KeywordsGroupedBtnRemoveGroup @remove="remove()" />
        </template>

        <template #footer>
            <KeywordsGroupedControlSkillsCount
                v-model="skillsCount"
                :currentCount="skills.length"
            />
        </template>
    </Keywords>
</template>
<script>
import KeywordsGroupedControlSkillsCount from './KeywordsGroupedControlSkillsCount.vue'
import KeywordsGroupedBtnRemoveGroup from './KeywordsGroupedBtnRemoveGroup.vue'
import Keywords from './Keywords.vue'

export default {
    props: {
        group: Object,
    },

    components: {
        KeywordsGroupedBtnRemoveGroup,
        KeywordsGroupedControlSkillsCount,
        Keywords,
    },

    computed: {
        skills() {
            return this.group.skills
        },

        skillsCount: {
            get() {
                return this.group.groupSkillsCount
            },
            set(newValue) {
                this.$emit('change', {
                    ...this.group,
                    groupSkillsCount: newValue,
                })
            },
        },
    },

    methods: {
        skillsChanged(newSkills) {
            this.$emit('change', {
                ...this.group,
                skills: newSkills,
            })
        },

        remove() {
            this.$emit('remove')
        },
    },
}
</script>
