<template lang="pug">
div
    .card.mb-3
        .card-body(style="position: relative")
            form(@submit.stop.prevent="")
                .form-row
                    .col
                        h4 Step 1 - Enter essentials
                        hr
                        .form-group
                            b-alert(
                                :show="!hasEnoughSkillsProvided",
                                variant="danger"
                            )
                                | Add {{ skillsNededToUnlock }} more {{ skillsNededToUnlock === 1 ? 'skill' : 'skills' }}
                                |
                                | to unlock matches number
                            label Essential skills
                            SkillsBuilder(
                                :skillGroups="skillGroups",
                                :skills="job.skills",
                                @changeSkills="alterSkills($event)"
                                @changeGroups="alterSkillGroups($event)"
                            )
</template>
<script>
import { SkillsBuilder } from '@/components/SkillsBuilder'

export default {
    name: 'Essentials',

    components: {
        SkillsBuilder,
    },

    props: {
        job: {
            type: Object,
            required: true,
        },
    },

    computed: {
        skillsNededToUnlock() {
            return this.requiredSkillsNumber - this.job.skills.length
        },

        hasEnoughSkillsProvided() {
            return this.skillsNededToUnlock <= 0
        },

        highestExpWithinSkills() {
            return this.job.skills.reduce(
                (acc, { yearsOfExperience: years }) => (years < acc ? acc : years),
                0
            )
        },

        skillGroups() {
            return this.job.groupedSkills
        },
    },

    watch: {
        hasEnoughSkillsProvided(val) {
            if (!val) {
                this.$emit('job', { fund: [0, 50000] })
            }
        },
    },

    methods: {
        alterSkills(newSkills) {
            this.$emit('update:job', {
                ...this.job,
                skills: newSkills,
            })
        },

        alterSkillGroups(newGroupedSkills) {
            const changedJob = {
                ...this.job,
                groupedSkills: newGroupedSkills,
            }
            this.$emit('update:job', changedJob)
        },
    },

    data() {
        return {
            requiredSkillsNumber: 0,
        }
    },
}
</script>
