<template lang="pug">
.card.mb-3
  .card-body
    h4 Predicted Results
    hr
    h5.text-muted Matches:
    h5 {{ numberOfCandidates }} essential
    h5.text-muted Cut down to:
    h5 {{ numberOfCandidatesDesirable }} because of desirables 
      span(style="color: red")  (▾ {{ percentageCut.nice }}% cut)
    h5 {{ numberOfCandidatesSalary }} because of desirables and salary
      span(style="color: red")  (▾ {{ percentageCut.salary }}% cut)
    small.text-muted
      i Hint: click on the legend below to hide selected chart
    Chart(:chart-data="distribution" :height="400")
</template>
<script>
import { mixins, Bar } from 'vue-chartjs'
const { reactiveProp } = mixins

let Chart = {
    extends: Bar,
    mixins: [reactiveProp],
    data() {
        return {
            options: {
                title: {
                    display: true,
                },
                legend: {
                    display: true,
                },
                responsive: true,
                maintainAspectRatio: false,
                aspectRatio: 1,
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                suggestedMin: 0,
                                suggestedMax: 5,
                            },
                        },
                    ],
                },
            },
        }
    },
    mounted() {
        this.renderChart(this.chartData, this.options)
    },
}

export default {
    name: 'Chart',

    components: {
        Chart,
    },

    props: {
        job: Object,
    },

    computed: {
        numberOfCandidates: (vm) => vm.countCandidates('must'),
        numberOfCandidatesDesirable: (vm) => vm.countCandidates('nice'),
        numberOfCandidatesSalary: (vm) => vm.countCandidates('salary'),

        percentageCut: (vm) => {
            const mustNumber = vm.numberOfCandidates
            const niceNumber = vm.numberOfCandidatesDesirable
            const salaryNumber = vm.numberOfCandidatesSalary

            const calculatePercentage = (droppedNumber) =>
                Math.round((100 * droppedNumber) / mustNumber - 100) * -1

            return {
                nice: calculatePercentage(niceNumber) || 0,
                salary: calculatePercentage(salaryNumber) || 0,
            }
        },

        distribution: (vm) => ({
            labels: vm.chartLabels,
            datasets: vm.chartDatasets,
        }),
        chartLabels: (vm) =>
            Object.keys(vm.chartData.data.must || vm.chartData.distributionInitialData),
        chartDatasets: (vm) => {
            const { data, distributionInitialData } = vm.chartData
            let datasets = [
                {
                    type: 'bar',
                    label: 'Candidates (essentials)',
                    backgroundColor: '#42bff4',
                    data: Object.values(data.must || distributionInitialData),
                },
                {
                    type: 'bar',
                    label: 'Candidates (essentials + desirables)',
                    backgroundColor: 'forestgreen',
                    data: Object.values(data.nice || distributionInitialData),
                },
                {
                    type: 'bar',
                    label: 'Candidates (essentials + desirables + salary)',
                    backgroundColor: 'orange',
                    data: Object.values(data.salary || distributionInitialData),
                },
            ]
            return datasets
        },
    },

    watch: {
        job: {
            handler() {
                if (this.job.skills.length >= 1) {
                    this.searchCandidates('must')
                    this.searchCandidates('nice')
                    this.searchCandidates('salary')
                } else {
                    this.chartData.data.must = null
                    this.chartData.data.nice = null
                    this.chartData.data.salary = null
                }
            },
            immediate: true,
            deep: true,
        },
    },

    data() {
        return {
            chartData: {
                data: {
                    must: null,
                    nice: null,
                    salary: null,
                },
                distributionInitialData: {
                    '0 - 3000': 0,
                    '3000 - 5000': 0,
                    '5000 - 8000': 0,
                    '8000 - 12000': 0,
                    '12000 - 15000': 0,
                    '15000 >': 0,
                },
            },
        }
    },

    methods: {
        searchCandidates(type) {
            const isTypeNice = type === 'nice'
            const isTypeSalary = type === 'salary'

            const skills = this.job.skills.map((skill) => skill.name)
            const skillsNice = this.job.skillsNice.map((skill) => skill.name)
            const allSkills = [...skills, ...(isTypeNice || isTypeSalary ? skillsNice : [])]

            let url = `/recruiter/candidatesForJob` + `?skills=${allSkills.join(',')}`

            if (isTypeSalary) {
                url += `&salary_min=${this.job.fund[0]}`
                url += `&salary_max=${this.job.fund[1]}`
            }

            this.$store.state.backend
                .get(url)
                .then((res) => this.setCandidatesForSkills(type, res.data.exact.distribution))
                .catch((err) => alert(err))
        },

        countCandidates(type) {
            return this.chartData.data[type]
                ? Object.values(this.chartData.data[type]).reduce((acc, curr) => acc + curr, 0)
                : 0
        },

        setCandidatesForSkills(type, data) {
            this.chartData.data[type] = data
        },
    },
}
</script>
