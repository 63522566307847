<template lang="pug">
  table.table.table-striped.table-borderless.keywords-skill-table
    thead
      tr
        th SKILL NAME
        th SKILL LEVEL
        th EXPERIENCE (years)
        th
    tbody
      KeywordsSkillTableRow(
        v-for="(skill, index) in skills" :key="skill.name + index"
        :skill="skill"
        @change="changeSkill(index, $event)"
        @remove="removeSkill(index)"
      )

      tr(v-if="skills.length === 0")
        td(colspan="4").text-center No skills added yet
</template>
<script>
import KeywordsSkillTableRow from './KeywordsSkillTableRow.vue'

export default {
    props: {
        skills: {
            type: Array,
            required: false,
            default: () => [],
        },
    },

    components: {
        KeywordsSkillTableRow,
    },

    methods: {
        changeSkill(index, skill) {
            this.$emit('change', Object.assign([], this.skills, { [index]: skill }))
        },
        removeSkill(index) {
            this.$emit('change', [
                ...this.skills.slice(0, index),
                ...this.skills.slice(index + 1),
            ])
        },
    },
}
</script>
<style lang="sass" scoped>
.keywords-skill-table thead th
  text-align: center
</style>
