<template lang="pug">
  b-button.align-self-center(
    variant="primary",
    @click="emitRemove()",
    size="sm",
    data-test="btn-remove-group"
  )
    font-awesome-icon(:icon="iconTrash" v-b-tooltip="'Remove group'")
    span Remove group
</template>
<script>
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'

export default {
    methods: {
        emitRemove() {
            this.$emit('remove')
        },
    },

    computed: {
        iconTrash: () => faTrashAlt,
    },
}
</script>
