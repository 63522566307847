<template lang="pug">
  b-form.mb-1(
      @submit.prevent='addKeyword' style="padding: 0 0px"
      data-test="KeywordsAddSkill"
    )
    .form-row.justify-content-between
      .form-group(class="col-6")
        b-input-group
          b-form-input(
            v-model='name'
            :placeholder='placeholder'
            type='text'
            @keyup.enter='addKeyword'
            data-test="input-add"
          )
          b-input-group-append
            b-button(variant="primary" @click='addKeyword' data-test="btn-add") + Add
</template>
<script>
export default {
    props: {
        placeholder: String,
    },

    data() {
        return {
            name: '',
        }
    },

    methods: {
        addKeyword(evt) {
            if (this.name.length > 0) {
                evt.preventDefault()
                this.$emit('add', this.name)
                this.name = ''
            }
        },
    },
}
</script>
