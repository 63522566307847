<template lang="pug">
.card
  .card-body
    form
      .form-row.mb-3
        .col-6.col-md-6
          multiselect(
            :value="job.company"
            :options="companies"
            @input="setCompany($event);"
            label="name"
            placeholder="Select company"
            :disabled="companies.length === 1"
          )

      .form-row.mb-3
        .col-6
          .form-group.mb-0
            label(for="perks") Perks
            multiselect(
              :value="job.company.perks",
              :options="$store.state.filterDefaults.perks",
              :multiple="true",
              :close-on-select="false",
              :clear-on-select="false",
              :preserve-search="true",
              placeholder="Pick perks"
              :preselect-first="true"
              :disabled="true"
            )
          small.text-muted
            i Hint: you can change perks in Settings

      .form-row
        .col-6
          .form-group.mb-3
            label(for="responsiblePersons") Responsible persons
            multiselect(
              :options="responsiblePersons",
              track-by="user_id"
              label="name"
              placeholder="Responsible persons"
              @input="addResponsiblePerson($event)"
            )
      .form-row
        .col-6
          .form-group.mb-3
            b-table(
              :items="job.responsiblePersons"
              :fields="['user_id','name','action']"
              empty-text="No person assigned"
              show-empty
              )
              template(v-slot:cell(action)="data")
                b-button(variant="danger" size="sm" @click="removeResponsiblePerson(data.item)") Remove       
</template>
<script>
import { STORE_MODULE } from '@/enums'
import Multiselect from 'vue-multiselect'
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers(STORE_MODULE.AUTH)

export default {
    name: 'Company',

    components: {
        Multiselect,
    },

    props: {
        job: Object,
    },

    computed: {
        ...mapState(['userAccount']),
    },

    watch: {
        companies() {
            if (this.companies.length === 1) {
                this.job.company = this.companies[0]
                this.job.company.perks = this.companies[0].perks ? this.companies[0].perks : []
                this.$emit('change-tab', 1)
            }
        },

        userAccount: {
            handler() {
                if (this.userAccount) this.fetchAllCompanies()
            },
            immediate: true,
        },
    },

    data() {
        return {
            selectedPerks: [],
            companies: [],
            responsiblePersons: [],
        }
    },

    methods: {
        updateJob(payload) {
            this.$emit('update:job', { ...this.job, ...payload })
        },

        fetchAllCompanies() {
            this.$store.state.backend
                .get('jobBuilder/company/all')
                .then((ret) => (this.companies = ret.data))
                .catch((error) => alert(error))
        },

        setCompany(company) {
            this.updateJob({ company, location: [] })
            this.$store.state.backend
                .get(`jobBuilder/responsiblePersons/byCompany/${company.id}`)
                .then((ret) => {
                    this.responsiblePersons = ret.data
                    this.job.responsiblePersons = []
                })
                .catch((error) => alert(error))
        },

        addResponsiblePerson(user) {
            this.job.responsiblePersons.push(user)
            this.job.responsiblePersons = this.job.responsiblePersons.sort(this.sortByName)
            this.responsiblePersons = this.responsiblePersons.filter(
                (item) => item.user_id != user.user_id
            )
            this.responsiblePersons = this.responsiblePersons.sort(this.sortByName)
        },

        removeResponsiblePerson(user) {
            this.job.responsiblePersons = this.job.responsiblePersons.filter(
                (item) => item.user_id != user.user_id
            )
            this.job.responsiblePersons = this.job.responsiblePersons.sort(this.sortByName)
            this.responsiblePersons.push(user)
            this.responsiblePersons = this.responsiblePersons.sort(this.sortByName)
        },
        sortByName(a, b) {
            if (a.name > b.name) {
                return 1
            }
            if (a.name < b.name) {
                return -1
            }
            return 0
        },
    },
}
</script>
<style lang="sass">
.card
  box-shadow: 0 2px 6px 0 hsla(0,0%,0%,0.1)
  border: 0
.custom-file-label
  text-overflow: ellipsis
  overflow: hidden
  padding-right: 80px
  white-space: nowrap
.custom-file-input
  cursor: pointer
</style>
