<template lang="pug">
.card
  .card-body
    form
      div(
        v-if="$route.path === '/job/add'"
        :class="{ 'd-none': Boolean(jobToEdit) }"
      )
        multiselect.w-50(
          :options="jobs"
          label="title"
          placeholder="Select job to clone"
          @select="copyJobEditHere"
        )
        hr

      .form-row
        .col-6
          .form-group
            label(for='jobTitle') Job title
            b-input(
              :value='job.title'
              @input="updateJob({title:$event})"
              name='jobTitle'
              type='text'
              placeholder='ex. Chief Data Analyst, Technical Support Engineer'
            )
            small.invalid-feedback.help-block

      .form-row
        .col-6
          .form-group
            label(for='jobDescription') Main purpose of job
            b-form-textarea(
              :value="job.description"
              @input="updateJob({description:$event})"
              name='jobDescription'
              rows='6'
              max-rows="20"
              placeholder='What is this person being hired to do'
            )
            small.text-muted Supports Markdown styling
            small.invalid-feedback.help-block

        .col-6
          .form-group
            label.text-muted(for='jobDescriptionPreview') Preview
            vue-markdown(:source="job.description === '' ? jobDescriptionExample : job.description")

      .form-row.mb-2
        .col-6
          .form-group
            label Select department
            Department(
              :job="job"
              :company-id="job.company.id"
              @send-project-id="updateJob({project: $event})"
            )

      .form-row
        .col-6
          .form-group
            label(for='contractType') Contract type
            multiselect(
              :value="job.contractType"
              @input="updateJob({contractType:$event})"
              :options="['B2B', 'UoP','Contract','Flexible','Other']"
              :searchable="false",
              :multiple="true"
              :close-on-select="false",
              :show-labels="false"
              placeholder="Pick a type"
            )
            small.invalid-feedback.help-block

      .form-row
        .col-6
          .form-group
            label(for='recruitmentTime') Recruitment time
            b-input-group(append="days")
              b-input(
              :value="job.recruitmentTime"
              @input="updateJob({recruitmentTime:$event})"
                name='recruitmentTime'
                type='number'
                placeholder='Enter how many days from apply to offer')
            small.invalid-feedback.help-block
      hr
      .form-row.mb-2
        .col-6
          b-form-checkbox.mb-2(
            :checked="job.salaryPrivate"
            @input="updateJob({ salaryPrivate: $event })"
            switch
          )
            | Salary private

          b-form-checkbox.mb-2(
            :checked="job.flexibleHours"
            @input="updateJob({ flexibleHours: $event })"
            switch
            )
            | Flexible hours

          b-form-checkbox.mb-2(
            :checked="job.relocationPackage"
            @input="updateJob({ relocationPackage: $event })"
            switch
          )
            | Relocation package

          b-form-checkbox.mb-2(
            :checked="job.holidaysPaid"
            @input="updateJob({ holidaysPaid: $event })"
            switch
          )
            | Holidays paid

          b-form-checkbox.mb-2(
            :checked="job.options"
            @input="updateJob({ options: $event })"
            switch
          )
            | Stock options

          b-form-textarea.mb-2(
            v-if="job.options"
            :value="job.optionsDetails"
            @input="updateJob({ optionsDetails: $event })"
            name='optionsDetails'
            rows='6'
            placeholder='What stock options do you offer'
          )

          b-form-checkbox.mb-2(
            :checked="job.bonus"
            @change="updateJob({ bonus: $event })"
            switch
          )
            | Bonuses

          b-form-textarea.mb-2(
            v-if="job.bonus"
            :value='job.bonusDetails'
            @input="updateJob({ bonusDetails: $event })"
            name='bonusDetails'
            rows='6'
            placeholder='What bonus options do you offer'
          )

        .col-6
          label Job visibility
          b-form-checkbox(
            :checked="job.visibilitySite"
            @change="updateJob({ visibilitySite: $event })"
            switch
          )
            | Refair.me

          b-form-checkbox(
            :checked="job.visibilityWhiteLabel"
            @change="updateJob({ visibilityWhiteLabel: $event })"
            switch
          )
            | Company referals

          b-form-checkbox(
            :checked="job.visibilityApi"
            @change="updateJob({ visibilityApi: $event })"
            switch
          )
            | Career site

      hr
      .form-row
        .col-6
          .form-group
            label Recruitment process
            multiselect.mb-2(
              :value="job.recruitmentProcess"
              @input="updateJob({ recruitmentProcess: $event })"
              :options="processes"
              label="name"
              track-by="id"
              placeholder="Select recruitment process"
            )
          .form-row
            .col-12
              .form-group
              label Recruitment process details
              b-table(
              :items="job.recruitmentProcess.stages"
              :fields="['stage','name','days_to_next_stage','elapsed_time','details']"
              show-empty
              empty-text="No processes selected"
              )

      hr
      .form-row.mt-2
        .col-6
          .form-group
            label.col-form-label(for='travel') % Travel of role
            Slider(
              :value="job.travelPercentage"
              @change="updateJob({ travelPercentage: $event })"
              name='travel'
              :min='0'
              :max='100'
              :tooltip="'focus'"
              :interval='5'
              lazy
            )
            span.small {{job.travelPercentage}}%

          .form-group
            label.col-form-label(for='remoteWork') % Work from home
            Slider(
              :value="job.remotePercentage"
              @change="updateJob({ remotePercentage: $event })"
              name='remoteWork'
              :min='0'
              :max='100'
              :tooltip="'focus'"
              :interval='5'
              lazy
            )
            span.small {{job.remotePercentage}}%

        .col-6.pl-4
          .form-row
            label
              | Weekly work breakdown

          .form-row
            .col-12
              .form-group.row.mt-0.mb-0(v-for="(object, key, index) in job.workBreakdown")
                label.col-4(style="font-weight: normal")
                  | {{ object.label }}
                .col-5.pl-0.pr-0
                  Slider(
                    v-model='object.value'
                    name='expslider'
                    :process-style="{backgroundColor: labelColors[key]}",
                    :min='0'
                    :max='100'
                    :tooltip="'focus'"
                    :interval='5'
                    lazy
                  )
                .col-3
                  b-input-group(append="%")
                    b-form-input(v-model="object.value" type="number" step="5")

              span(
                style="display: flex; justify-content: flex-end"
                :style="{color: breakdownReduced === 100 ? 'black' : 'red'}"
              )
                | {{ breakdownReduced }}%
      hr

      .form-row(v-if="showFrameworks")
        .col-12
          hr(style="border-top: 2px solid red")

        .col-6
          .form-group
            label(for='frameworksMust') Must have
            Keywords(
              :skills='job.frameworksMust'
              @change="updateJob({frameworksMust: $event})"
              placeholder="eg. Angular"
              name="frameworksMust")
            small.invalid-feedback.help-block sample error

        .col-6
          .form-group
            label(for='frameworksNice') Nice to have
            Keywords(
              :skills='job.frameworksNice'
              @change="updateJob($event, 'frameworksNice')"
              placeholder="eg. Vue.js"
              name="frameworksNice")
            small.invalid-feedback.help-block sample error

      .form-row(v-if="showMethodologies")
        .col-12
          hr(style="border-top: 2px solid purple")

        .col
          .form-group
            label(for='methodologiesMust') Must have
            Keywords(
              :skills='job.methodologiesMust'
              @change="updateJob($event, 'methodologiesMust')"
              placeholder="eg. Agile"
              name="methodologiesMust")
            small.invalid-feedback.help-block sample error

        .col
          .form-group
            label(for='methodologiesNice') Nice to have
            Keywords(
              :skills='job.methodologiesNice'
              @change="updateJob($event, 'methodologiesNice')"
              placeholder="eg. Lean"
              name="methodologiesNice")
            small.invalid-feedback.help-block sample error

</template>
<script>
import VueMarkdown from 'vue-markdown'
import Multiselect from 'vue-multiselect'
import Slider from 'vue-slider-component'
import { Department } from './components'
import { Keywords } from '@/components/SkillsBuilder'
import { createNamespacedHelpers } from 'vuex'
import { STORE_MODULE } from '@/enums'

const { mapState } = createNamespacedHelpers(STORE_MODULE.AUTH)

export default {
    name: 'Specification',

    components: {
        Department,
        Keywords,
        Slider,
        VueMarkdown,
        Multiselect,
    },

    props: {
        job: Object,
    },

    computed: {
        ...mapState(['userAccount']),

        breakdownReduced: (vm) =>
            vm.job.workBreakdown.reduce((acc, { value }) => acc + Number(value), 0),

        process: {
            get() {
                return this.process
            },
            set(val) {
                this.selectedProcessStages = val ? val.stages : []
                this.process = val
            },
        },
    },

    mounted() {
        this.fetchProcesses()
    },

    data() {
        return {
            jobs: [],

            hasProcesses: false,
            processes: [],
            selectedProcessStages: [],

            showFrameworks: false,
            showMethodologies: false,

            jobDescriptionExample:
                'What can you do with Markdown styling:\n- for example lists\n- with *italic*\n- or **bold** styling',

            labelColors: ['#10B13A', '#EC2E15', '#EC8015', '#117992', '#FFB770', '#007bff'],
        }
    },

    methods: {
        updateJob(payload) {
            this.$emit('update:job', { ...this.job, ...payload })
        },

        fetchProcesses() {
            this.$store.state.backend
                .get(`/admin/processes/${this.userAccount.id}`)
                .then((res) => (this.processes = res.data))
                .catch((err) => alert(err))
        },
    },
}
</script>
<style lang="sass" scoped>
.card
  box-shadow: 0 2px 6px 0 hsla(0,0%,0%,0.1)
  border: 0
.clickable
  cursor: pointer
label:not(.form-check-label)
  font-weight: bold
  font-size: 16px
</style>
