<template lang="pug">
  div.text-center
    p Looks like all is good and you are ready to submit your job proposal. Click button below to submit!
    
    b-button(
      v-if="isJobEdit"
      size="lg"
      variant="success"
      type='button'
      @click='updateJob'
      :disabled="jobUpdated"
    )
      | {{jobUpdated ? 'Job updated' : 'Update job'}}
    
    b-button(
      v-else
      size="lg"
      variant="success"
      type='button'
      @click='addJob'
    )
      | {{jobId ? 'Job added' : 'Confirm adding new job'}}
    br
    b-button-group.mt-2(v-if="jobId")
      b-button(variant="outline-success" @click="goToEdit") Edit job
      b-button(variant="outline-success", :to="{ name: ROUTE.JOB_DETAILS, params: { jobId } }") Show job
      b-button(variant="outline-success" @click="reset") Add new job

</template>
<script>
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { ROUTE } from '@/enums'

export default {
    name: 'Confirmation',

    props: {
        job: {
            type: Object,
            required: true,
        },
    },

    activated: function () {
        this.jobUpdated = false
    },

    computed: {
        isJobEdit: (vm) => vm.$route.fullPath.includes('job-edit'),
        iconCheck: () => faCheckCircle,
        iconCross: () => faTimesCircle,
    },

    data() {
        return { ROUTE, jobId: '', jobUpdated: false }
    },

    methods: {
        reset() {
            this.$emit('reset')
        },

        isCyclic(obj) {
            const seenObjects = []

            function detect(obj) {
                if (obj && typeof obj === 'object') {
                    if (seenObjects.indexOf(obj) !== -1) {
                        return true
                    }
                    seenObjects.push(obj)
                    for (let key in obj) {
                        if (obj.hasOwnProperty(key) && detect(obj[key])) {
                            return true
                        }
                    }
                }
                return false
            }

            return detect(obj)
        },

        addJob() {
            this.isCyclic(this.job)

            this.$store.state.backend
                .post('/jobBuilder', this.job)
                .then((ret) => (this.jobId = ret.data.jobId))
                .catch((error) => alert(error.message))
        },

        updateJob() {
            this.$store.state.backend
                .post('/admin/update-job', this.job)

            .then(() => {
              this.jobUpdated = true
            })
                .catch((error) => alert(error))
        },
    },
}
</script>
