<template>
    <div class="pt-3">
        <slot name="header" />
        <KeywordsAddSkill :placeholder="placeholder" @add="addSkill($event)" />
        <slot name="edit" />
        <KeywordsSkillTable :skills="skills" @change="changeSkills($event)" />
        <slot name="footer" />
    </div>
</template>
<script>
import KeywordsAddSkill from './KeywordsAddSkill.vue'
import KeywordsSkillTable from './KeywordsSkillTable.vue'

export default {
    props: {
        skills: {
            type: Array,
            required: false,
            default: () => [],
        },

        placeholder: {
            type: String,
            required: false,
            default: () => 'eg. PHP',
        },
    },

    components: {
        KeywordsAddSkill,
        KeywordsSkillTable,
    },

    methods: {
        changeSkills(changedSkills) {
            this.$emit('change', changedSkills)
        },

        addSkill(name) {
            const hasSkill = this.skills.filter((skill) => skill.name === name).length > 0
            if (!hasSkill) {
                const newSkill = {
                    name,
                    seniority: 0,
                    yearsOfExperience: 3,
                }
                this.$emit('change', [...this.skills, newSkill])
            }
        },
    },
}
</script>
