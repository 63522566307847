<template>
    <div class="card mb-3">
        <div class="card-body" style="position: relative">
            <form @submit.stop.prevent="">
                <div class="form-row">
                    <div class="col">
                        <h4>Step 2 - Desirables</h4>
                        <hr />

                        <div class="form-group">
                            <label class="d-block">Essential skills (from step 1)</label>
                            <Essentials :skills="job.skills" :groups="job.groupedSkills" />
                        </div>

                        <div class="form-group">
                            <label>Desired skills</label>
                            <SkillsBuilder
                                :skillGroups="job.groupedSkillsNice"
                                :skills="job.skillsNice"
                                @changeSkills="alterSkills($event)"
                                @changeGroups="alterSkillGroups($event)"
                            />
                            <small class="invalid-feedback help-block">sample error</small>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { SkillsBuilder } from '@/components/SkillsBuilder'
import { Essentials } from './components'

export default {
    name: 'Desirables',

    components: {
        SkillsBuilder,
        Essentials,
    },

    props: {
        job: Object,
    },

    methods: {
        alterSkills(newSkills) {
            this.$emit('update:job', {
                ...this.job,
                skillsNice: newSkills,
            })
        },

        alterSkillGroups(newGroupedSkills) {
            const changedJob = {
                ...this.job,
                groupedSkillsNice: newGroupedSkills,
            }
            this.$emit('update:job', changedJob)
        },
    },
}
</script>
