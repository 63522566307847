<template lang="pug">
b-form.mb-1(@submit.prevent='', style='padding: 0 0px')
    .form-row.justify-content-between
        .form-group.col-6
            b-input-group
                b-form-input(
                    v-model='name',
                    placeholder='Group name',
                    type='text',
                    @keyup.enter='',
                    data-test='skills-input-groupname'
                )
                b-input-group-append
                    b-button(
                        variant='primary',
                        @click='addGroup',
                        data-test='skills-btn-creategroup'
                    ) Create skill group
</template>
<script>
export default {
    data() {
        return {
            name: '',
        }
    },
    methods: {
        addGroup() {
            this.$emit('add', this.name)
            this.name = ''
        },
    },
}
</script>
