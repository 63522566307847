<template lang="pug">
    span
        span(class="group")
            strong Ungrouped:
            span(
                v-for="skill in skills", :key="skill.name"
                class="skill"
            ) {{ skill.name }}
        span(
            v-for="group in groups"
            :key="group.groupName"
            :data-test="'essentialslist-group-' + group.groupName"
            class="group"
        )
            strong {{ group.groupName }}:
            span(
                v-for="skill in group.skills"
                :key="skill.name"
                :data-test="'skill-' + skill.name"
                class="skill"
            ) {{ skill.name }}

</template>
<script>
export default {
    name: 'Essentials',

    props: {
        skills: Array,
        groups: Array,
    },
}
</script>
<style scoped>
.skill {
    color: #42bff4;
    border: 1px solid #42bff4;
    padding: 3px 10px;
    margin: 0 5px 5px 0;
    border-radius: 8px;
    display: inline-block;
    white-space: no-wrap;
}

.group strong {
    margin: 0 1em;
}
</style>
