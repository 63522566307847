<template>
    <b-form class="mb-1 p-0" @submit.prevent="">
        <p>
            Candidate should have
            <span v-if="value == currentCount">all</span>
            <span v-else>at least {{ value }}</span>
            &nbsp;
            <b-button-group size="sm">
                <b-button
                    variant="primary"
                    :disabled="value >= currentCount"
                    @click="increase()"
                    >↑</b-button
                >
                <b-button variant="primary" :disabled="value <= 1" @click="decrease()"
                    >↓</b-button
                >
            </b-button-group>
            &nbsp; skills from this group
        </p>
    </b-form>
</template>
<script>
export default {
    props: {
        value: {
            type: Number,
            required: true,
        },
        currentCount: {
            type: Number,
            required: true,
        },
    },

    methods: {
        increase() {
            this.$emit('input', this.value + 1)
        },

        decrease() {
            this.$emit('input', this.value - 1)
        },
    },
}
</script>
