<template lang="pug">
div
  ul.mb-3.nav.nav-justified
    li.nav-item(v-for="(value, index) in tabs")
      a.nav-link(
        :class="[{active: tabs[currentTab].name === value.name}]"
        :key="value.name"
        data-test="jobbuilder-tab-link"
        @click="currentTab = index"
      ) {{value.name}}

  .d-flex.justify-content-between
    b-button(
      v-show="!isFirstTab"
      variant="outline-secondary"
      size="sm"
      @click="changeTab(-1)"
    )
      | < Back
    b-button(
      v-show="!isLastTab"
      variant="info"
      size="sm"
      @click="changeTab(1)"
    )
      | Next: {{ nextComponentName }} >

  .row.pt-3
    .col
      transition(name="fade" mode="out-in")
        keep-alive
          component(
            :is="currentTabComponent"
            @reset="setDefaults"
            @change-tab="changeTab"
            :job="job"
            @update:job="updateJob($event)"
          )

  .d-flex.justify-content-between.mt-3
    b-button(
      v-show="!isFirstTab"
      variant="outline-secondary"
      size="sm"
      @click="changeTab(-1)"
    )
      | < Back
    b-button(
      v-show="!isLastTab"
      variant="info"
      size="sm"
      @click="changeTab(1)"
    )
      | Next: {{ nextComponentName }} >
</template>
<script>
import { JobBuilderCandidates as Candidates } from '@/components/JobBuilderCandidates'
import {
    Chart,
    Company,
    Confirmation,
    Desirables,
    Essentials,
    Location,
    Salary,
    Specification,
    Preview,
} from './components'

export default {
    name: 'JobBuilder',

    components: {
        Candidates,
        Chart,
        Company,
        Confirmation,
        Desirables,
        Essentials,
        Location,
        Preview,
        Salary,
        Specification,
    },

    computed: {
        currentTabComponent: (vm) => vm.tabs[vm.currentTab].comp,

        isFirstTab: (vm) => vm.currentTab === 0,
        isLastTab: (vm) => vm.currentTab === vm.tabs.length - 1,
        nextComponentName: (vm) => {
            const nextComponentName = vm.tabs[vm.currentTab + (vm.isLastTab ? 0 : 1)].name
            return nextComponentName
        },
    },

    data() {
        return {
            currentTab: 0,
            tabs: [
                {
                    name: 'Company',
                    comp: Company,
                },
                {
                    name: 'About job',
                    comp: Specification,
                },
                {
                    name: 'Essentials',
                    comp: Essentials,
                },
                {
                    name: 'Desirables',
                    comp: Desirables,
                },
                {
                    name: 'Location',
                    comp: Location,
                },
                {
                    name: 'Salary',
                    comp: Salary,
                },
                {
                    name: 'Preview',
                    comp: Preview,
                },
                {
                    name: 'Candidates',
                    comp: Candidates,
                },
                {
                    name: 'Submit',
                    comp: Confirmation,
                },
            ],

            job: {
                bonus: false,
                bonusDetails: '',
                company: {
                    id: '',
                },
                responsiblePersons: [],
                contractType: [],
                currency: 'PLN',
                description: '',
                exp: 3,
                frameworksMust: [],
                frameworksNice: [],
                fund: [0, 50000],
                holidaysPaid: false,
                keywords: [],
                location: [], // missing in db
                methodologiesMust: [],
                methodologiesNice: [],
                options: false,
                optionsDetails: '',
                project: {
                    id: null,
                },
                recruitmentProcess: {},
                recruitmentTime: 21,
                relocation: false,
                relocationPackage: false,
                remote: true,
                remotePercentage: 20,
                flexibleHours: false,
                salaryPrivate: false,
                skills: [],
                groupedSkills: [],
                skillsNice: [],
                groupedSkillsNice: [],
                title: '',
                travelPercentage: 20,
                visibilityApi: true,
                visibilitySite: true,
                visibilityWhiteLabel: true,
                workBreakdown: [
                    { label: 'NewFeatures', value: 20 },
                    { label: 'Bug fixing', value: 20 },
                    { label: 'Self-development', value: 15 },
                    { label: 'Meetings', value: 15 },
                    { label: 'Support', value: 15 },
                    { label: 'Documentation', value: 15 },
                ],
            },
        }
    },

    created: function () {
        if (this.$route.params.job) {
            this.job = this.$route.params.job
        }

        this.currentTab = 0
    },

    methods: {
        changeTab(number) {
            this.currentTab += number
        },
        setDefaults() {
            window.location.reload(true)
        },
        updateJob(newJob) {
            this.job = newJob
        },
    },
}
</script>
<style lang="sass">
.card
  box-shadow: 0 2px 6px 0 hsla(0,0%,0%,0.1)
  border: 0
a
  color: inherit
  cursor: pointer
.nav-link.active
  border-bottom: 4px solid #28a745
.nav-justified
  border-bottom: 0.25px solid lightgray
.fade-enter-active, .fade-leave-active
  transition: opacity .1s
.fade-enter, .fade-leave-to
  opacity: 0
</style>
