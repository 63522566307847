<template lang="pug">
    div
        Keywords(
            class="keywords"
            :skills="skills",
            @change="alterSkills($event)",
            data-test="skills-root"
        )
        BtnAddGroup(@add="addGroup")
        KeywordsGrouped(
            v-for="(skillsGroup, index) in skillGroups",
            :key="skillsGroup.groupName",
            class="keywords grouped"
            :group="skillsGroup"
            @change="changeGroupAtIndex(index, $event)",
            @remove="removeGroupAtIndex(index)"
            :data-test="'skillgroup-' + skillsGroup.groupName"
        )
        small.invalid-feedback.help-block sample error
</template>
<script>
import Keywords from './Keywords.vue'
import KeywordsGrouped from './KeywordsGrouped.vue'
import BtnAddGroup from './BtnAddGroup.vue'

export default {
    components: {
        Keywords,
        KeywordsGrouped,
        BtnAddGroup,
    },

    props: {
        skillGroups: Array,
        skills: Array,
    },

    methods: {
        alterSkills(skills) {
            this.$emit('changeSkills', skills)
        },

        addGroup(name) {
            const newGroup = {
                groupName: name,
                isGrouped: true,
                groupSkillsCount: this.skills.length,
                skills: this.skills,
            }
            const previousSkillGroups = this.skillGroups || []
            const changedGroups = [...previousSkillGroups, newGroup]
            this.$emit('changeGroups', changedGroups)
            this.$nextTick(() => this.$emit('changeSkills', []))
        },

        removeGroupAtIndex(index) {
            const newGroups = [
                ...this.skillGroups.slice(0, index),
                ...this.skillGroups.slice(index + 1),
            ]
            this.$emit('changeGroups', newGroups)
        },

        changeGroupAtIndex(index, modifiedGroup) {
            if (typeof index !== 'number') {
                throw new TypeError('Cannot emitGroupAtIndex for non-numeric index')
            }
            const newGroups = Object.assign([], this.skillGroups, { [index]: modifiedGroup })
            this.$emit('changeGroups', newGroups)
        },
    },
}
</script>
<style lang="sass" scoped>
.keywords.grouped
  border: 1px solid #e5e5e5
  padding: 2px
  margin-top: 20px
  margin-bottom: 15px
  line-height: 1.5
  border-radius: 0.2rem

.keywords .table-container
  padding: 0px 0px 20px
  background: white

.keywords.grouped .table-container
  padding: 0px 0px 20px
  background: white
</style>
