<template lang="pug">
.card.mb-3
    .card-body(style="position: relative")
      form(@submit.stop.prevent="")
        .form-row
          .col
            h4 Step 3 - Location
            hr
            .form-group
              label(for='jobLocation') Where job will be done from
              small.invalid-feedback.help-block
              multiselect(
                v-model="selectedLocation"
                :options="locations"
                :custom-label="customLabel"
                :multiple="true"
                :close-on-select="false"
                track-by="id"
                placeholder="Select locations"
              )
              
              b-form-checkbox.my-1(
                :checked="job.remote"
                @change="$emit('job', { remote: $event })"
                switch
              )
                | Remote position
              
              small.text-muted
                i If locations are left empty that means that it's a remote position.<br>
                i If both locations and remote are selected then candidate can choose between them.
</template>
<script>
import Multiselect from 'vue-multiselect'

export default {
    name: 'Location',

    components: {
        Multiselect,
    },

    model: {
        prop: 'job',
        event: 'update:job',
    },

    props: {
        job: {
            type: Object,
            required: true,
        },
    },

    computed: {
        selectedLocation: {
            get() {
                return this.job.location
            },
            set(value) {
                this.$emit('update:job', { ...this.job, location: value })
            },
        },
    },

    watch: {
        'job.location'(locations) {
            if (locations.length === 0) {
                this.$emit('job', { remote: true })
            }
        },

        'job.company': {
            immediate: true,
            handler() {
                this.fetchCities()
            },
        },
    },

    data() {
        return {
            locations: [],
        }
    },

    methods: {
        fetchCities() {
            this.$store.state.backend
                .get(`company/addresses/${this.job.company.id}`)
                .then((response) => (this.locations = response.data))
                .catch((err) => alert(err))
        },

        customLabel({ address, route, cityName, streetNumber, countryName, postalCode }) {
            let addressStr = address.streetName ? address.streetName : address.cityName
            addressStr += ` ${address.houseNumber}, ${postalCode} ${address.cityName}, ${address.countryName}`
            return addressStr
        },
    },
}
</script>
