<template>
    <div class="card">
        <div class="card-body">
            <h4>Step 4 - Salary</h4>

            <hr />

            <div class="form-group">
                <label class="col-form-label" for="salary">Gross monthly salary:</label>

                <Slider
                    name="salary"
                    v-model="job.fund"
                    :min="0"
                    :max="50000"
                    :interval="500"
                    :lazy="true"
                    :tooltip="'focus'"
                    :tooltip-dir="['left', 'right']"
                />

                <div class="d-flex mt-4">
                    <div>
                        <BInputGroup append="PLN" size="sm" style="flex-basis: 144px">
                            <BFormInput
                                v-model="job.fund[0]"
                                type="number"
                                step="500"
                                min="0"
                            />
                        </BInputGroup>
                    </div>

                    <div class="px-4"></div>

                    <div>
                        <BInputGroup append="PLN" size="sm" style="flex-basis: 144px">
                            <BFormInput
                                v-model="job.fund[1]"
                                type="number"
                                step="500"
                                min="0"
                            />
                        </BInputGroup>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Slider from 'vue-slider-component'

export default {
    name: 'Salary',

    components: { Slider },

    props: {
        job: Object,
    },
}
</script>
