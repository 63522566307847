<template>
    <Multiselect
        v-model="selectedProject"
        :options="departments"
        label="name"
        placeholder="Select department"
    />
</template>
<script>
import Multiselect from 'vue-multiselect'

export default {
    name: 'Department',

    components: { Multiselect },

    props: {
        companyId: {
            required: true,
            type: Number,
        },
        job: {
            type: Object,
            required: true,
        },
    },

    watch: {
        companyId: {
            handler(val) {
                if (val) this.fetchAllDepartments(this.companyId)
            },
            immediate: true,
        },

        departments() {
            if (this.job.projectId)
                this.selectedProject = this.departments.find(
                    (obj) => obj.id === this.job.projectId
                )
        },

        selectedProject(val) {
            this.emitProjectToParent()
        },
    },

    data() {
        return {
            departments: [],
            selectedProject: null,
        }
    },

    methods: {
        fetchAllDepartments(id) {
            this.$store.state.backend
                .get('jobBuilder/project/all/' + id)
                .then((ret) => (this.departments = ret.data.filter((dep) => dep.active)))
                .catch((error) => alert(error))
        },

        emitProjectToParent() {
            this.$emit('send-project-id', this.selectedProject || { id: '' })
        },
    },
}
</script>
<style lang="sass">
.card
  box-shadow: 0 2px 6px 0 hsla(0,0%,0%,0.1)
  border: 0
</style>
